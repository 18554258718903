import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { Card } from 'react-bootstrap'
import { Link } from 'gatsby'
import bussinesAnalyst from '../images/open-positions/business-system-analyst.png'
import monitoringEngineer from '../images/open-positions/monitoring-solutions-engineer.png'
import salesConsultant from '../images/open-positions/sales-design-consultant.png'
import zkArchitect from '../images/open-positions/zk-architect.png'
import dreamJob from '../images/open-positions/dream-job.png'

const Index = ({ location }) => {
  return (
    <Layout>
      <Seo pathName={location.pathname} title='Join the Team' />
      <section className='my-6'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <h1>join the team</h1>
              <p>
                Being the best in your class at a university is great, but it’s not a requirement in our book. We’re not your average company, and we’re not
                looking for your average person. We’re looking for problem solvers and people who are hungry for a challenge. We want people who take the
                difficult trail just to see what they find while they’re there. Being a team player and a good human are our main requirements, as well as
                having a good sense of humor and an interesting hobby.
              </p>
              <p>
                <strong>Do we want to see your resume?</strong> Of course, but we’d also like to see what you do in your free time.
              </p>
              <p>
                <strong>Ready for the best part?</strong> We’re hiring you to work from your sofa.
              </p>

              <h2>Open Positions</h2>

              <div className='bg-light p-5'>
                <div className='row'>
                  <div className='col-lg-4'>
                    <Card className='custom-cards'>
                      <Card.Img src={salesConsultant} alt='Sales and Design Consultant' />
                      <Card.Body className='text-left'>
                        <Link to='/jobs/sales-and-design-consultant/'>
                          <h4>Sales and Design Consultant</h4>
                        </Link>
                        <p className='text-muted'>
                          The perfect person for this role will have both UI/UX and Sales experience, and be able to read between the lines when a client needs
                          those efforts (Because surprise! clients don’t always SAY that’s what they need.).
                        </p>
                        <Link to='/jobs/sales-and-design-consultant/' className='more-link'>
                          read more
                        </Link>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className='col-lg-4'>
                    <Card className='custom-cards'>
                      <Card.Img src={dreamJob} alt='Here’s lookin’ at YOU' />
                      <Card.Body className='text-left'>
                        <Link to='/jobs/heres-lookin-at-you/'>
                          <h4>Here’s lookin’ at YOU!</h4>
                        </Link>
                        <p className='text-muted'>
                          Don’t see a role that fits your skillset? No worries! Let’s talk anyway.&nbsp; We are always open to a conversation, a coffee date,
                          and to talk about what would make you a good addition to our team.
                        </p>
                        <Link to='/jobs/heres-lookin-at-you/' className='more-link'>
                          read more
                        </Link>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Index
